<template>
  <v-card class="ma-2" :elevation="0" color="grey-lighten-4" flat outlined>
    <v-toolbar color="primary" :dark="true">
      <v-toolbar-title>MY VOTERS</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="pa-3">
      <v-chip-group selected-class="text-primary" column>
        <v-chip
          v-for="m in allowed_campaigns"
          :key="m.id"
          @click="campaign_changed(m)"
        >
          {{ m.name }}
        </v-chip>
      </v-chip-group>
      <v-divider />
      <div v-for="item in voters" class="pa-0 mb-1" :key="item.id">
        <div class="row mb-4" style="border-bottom: 1px solid grey">
          <div class="col-3" style="max-width: 70px">
            <image-field
              class="mt-2 ml-2"
              size="mini"
              :disabled="true"
              :show-zoom="false"
              :image-click="true"
              :grid="true"
              :width="0"
              :height="50"
              :value="item.photo"
            />
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-12 pb-0 title">
                {{ item.full_name }}
              </div>
              <div
                class="col-12 pt-0 pb-0"
                style="max-height: 45px; overflow: hidden"
              >
                {{ item.address }}
                <br />
                {{ item.current_address }}
              </div>
              <div
                class="col-12 pt-0 pb-0"
                style="max-height: 35px; overflow: hidden"
              >
                <span class="mdi mdi-phone-outline"></span>
                <a :href="'tel:' + item.mobile_number">
                  {{ item.mobile_number }}
                </a>
              </div>
              <div
                class="col-12 pt-0 pb-0"
                style="max-height: 35px; overflow: hidden"
              >
                <span class="mdi mdi-mail-outline"></span>
                <a :href="'mailto:' + item.email">{{ item.email }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Vue from "vue";
import { Select, Option } from "element-ui";
Vue.use(Select);
Vue.use(Option);
import ImageField from "@/components/custom/ImageField.vue";
export default {
  created() {
    if (this.allowed_campaigns.length == 1) {
      this.campaign_changed(this.allowed_campaigns[0]);
    }
  },
  components: {
    ImageField,
  },
  data() {
    return {
      valid: true,
      loading: false,
      voters: [],
      campaigns_id: null,
    };
  },
  methods: {
    campaign_changed(campaign) {
      if (!this.empty(campaign)) {
        this.campaigns_id = campaign.id;
        this.getMyVoters();
      }
    },
    getMyVoters() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api("public/my-voters", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.voters = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "MyVoters",
};
</script>
