<template>
  <v-card class="ma-2">
    <v-system-bar height="47" color="primary">
      <h3 class="white--text">{{ title }}</h3>
      <v-spacer></v-spacer>
      <v-btn
        title
        color="default"
        v-if="allowCreate"
        @click="newRecord"
        elevation="0"
      >
        <v-icon color="white">mdi-plus</v-icon>
        Refresh
      </v-btn>
    </v-system-bar>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col
          md="3"
          lg="3"
          sm="6"
          xs="12"
          v-for="b in ballotboxes"
          :key="b.id"
          style="text-align: center"
        >
          <span>
            <h5>{{ b.name }}</h5>
          </span>
          <GChart
            type="PieChart"
            :options="pieChartOptions"
            :data="getVotingStatusChartData(b)"
          />
          <GChart
            v-if="b.candidates.length > 0"
            type="ColumnChart"
            :options="barChartOptions"
            :data="getCandidateChartData(b.candidates)"
          />
          <!--<div style="font-size: 12px; color: black; font-weight: bold">
            <span>Total: {{ b.voters_count }}</span>
            <span>Yes Votes: {{ b.yes }}</span>
            <span>No Votes: {{ b.no }}</span>
            <span>Other Votes: {{ b.others }}</span>
            <span>
              Not Voted: {{ b.voters_count - b.yes - b.no - b.others }}
            </span>
          </div>-->
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Button, Pagination } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Pagination);
import { GChart } from "vue-google-charts";
export default {
  created() {
    this.getCampaigns();
  },
  components: {
    GChart,
  },
  data() {
    return {
      tableData: [],
      title: "Voting Day Dashboard",
      search: "",
      currentPage: 1,
      perPage: 10,
      total: 0,
      sort: "campaigns.name|asc",
      permissions: [],
      ballotboxes: [],
      loading: false,
      showForm: false,
      selectedRow: null,
      timer: "",
      barChartOptions: {
        legend: { position: "none" },
        vAxis: { minValue: 0 },
        //title: "Voter Response",
        colors: [
          "#ff0000",
          "#0a225f",
          "#7892d3",
          "#ff9898",
          "#67c23a",
          "#78735a",
          "#645252",
          "#9ebcff",
          "#ecf5ff",
          "#e6a23c",
        ],
        is3D: true,
      },
      pieChartOptions: {
        legend: {
          position: "right",
        },
        vAxis: { minValue: 0 },
        colors: [
          "#7892d3",
          "#0a225f",
          "#ff0000",
          "#858585",
          "#78735a",
          "#645252",
          "#9ebcff",
          "#ecf5ff",
          "#e6a23c",
        ],
        is3D: true,
      },
    };
  },
  methods: {
    newRecord() {
      this.showForm = true;
    },
    viewRecord(row) {
      this.selectedRow = row;
      this.showForm = true;
    },
    sizeChanged(page) {
      this.perPage = page;
      this.getData();
    },
    fitlerData() {
      this.currentPage = 1;
      this.getData();
    },
    campaign_changed(campaign) {
      if (!this.empty(campaign)) {
        this.campaigns_id = campaign.id;
        this.getData();
        this.timer = setInterval(this.getData, 60000);
      }
    },
    getData() {
      let f = {
        filter: this.search,
        page: this.currentPage,
        per_page: this.perPage,
        campaigns_id: this.campaigns_id,
        sort: this.sort,
      };
      this.loading = true;
      this.api("public/get-zero-day-dashboard", f)
        .then((response) => {
          this.loading = false;
          this.ballotboxes = response.data.ballotboxes;
          this.permissions = response.data.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCampaigns() {
      this.loading = true;
      let f = {};
      this.api("public/campaigns", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.campaigns = response.data;
            if (this.campaigns.length == 1) {
              this.campaign_changed(this.campaigns[0]);
            }
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getVotingStatusChartData(data) {
      console.log(data);
      let f = ["Status", "Voters", { role: "style" }, { role: "annotation" }];
      let cdata = [];
      cdata.push(f);
      let a = [
        "Not Voted (" +
          (parseInt(data.voters_count) - parseInt(data.voted_count)) +
          ")",
        parseInt(data.voters_count - data.voted_count),
        "#000000",
        parseInt(data.voters_count - data.voted_count),
      ];
      cdata.push(a);
      a = [
        "Voted (" + data.voted_count + ")",
        parseInt(data.voted_count),
        "#0a225f",
        parseInt(data.voted_count),
      ];
      cdata.push(a);
      /*
      a = [
        "No (" + data.no + ")",
        parseInt(data.no),
        "#ff0000",
        parseInt(data.no),
      ];
      cdata.push(a);
      a = [
        "Others (" + data.others + ")",
        parseInt(data.others),
        "#67c23a",
        parseInt(data.others),
      ];
      cdata.push(a);*/
      return cdata;
    },
    getCandidateChartData(data) {
      let f = [
        "candidate",
        "Voters",
        { role: "style" },
        { role: "annotation" },
      ];
      let cdata = [];
      cdata.push(f);
      data.forEach((el) => {
        let a = [
          this.empty(el.candidate) ? "Un Known" : el.candidate,
          parseInt(el.voters),
          el.color,
          parseInt(el.voters),
        ];
        cdata.push(a);
      });
      return cdata;
    },
  },
  computed: {
    allowCreate() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
    allowEdit() {
      return this.permissions.filter((e) => e.permission == "Edit").length > 0;
    },
    allowDisable() {
      return (
        this.permissions.filter((e) => e.permission == "Disable").length > 0
      );
    },
    allowReports() {
      return (
        this.permissions.filter((e) => e.permission == "View Reports").length >
        0
      );
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  name: "CampaignsDDayDashboard",
};
</script>
